import React from 'react';
import './switch-language.css';


export default function SwitchLanguage(props: any) {
    return (
        <div onClick={props.onClick} className="switch-language-container flex-center-all unselect">
            {
                props.lang === 'it'
                &&
                <img src="assets/svg/icons/it.svg" className="language-icon" alt="logo" />
            }
            {
                props.lang === 'en'
                &&
                <img src="assets/svg/icons/en.svg" className="language-icon" alt="logo" />
            }
        </div>
    )
}   