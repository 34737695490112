import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

// STILE GLOBALE ( STYLES )
import './styles/styles.scss';
// STILE GLOBALE ( FLEX )
import './styles/flex.scss';
// STILE GLOBALE ( WIDTH )
import './styles/width.scss';
// STILE PRIME REACT
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// STILE PRIME REACT OVERRIDE
import './primereact-override.css';


ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
