import React, { CSSProperties } from 'react';
import './button.scss';
import { FormattedMessage } from 'react-intl';


export interface ButtonProps {
    onClick: (e?: any) => void,
    label?: string,
    class?: string,
    style?: CSSProperties,
}

export default function YellowButton(props: ButtonProps) {
    return (
        <div className={"j-center row unselect mb-4 " + props.class} style={{ width: '75%', ...props.style }} >
            <div className={'button unselect flex-center-all '} onClick={props.onClick} >
                <FormattedMessage id={props.label ? props.label : "button.know-more"}>
                    {
                        (msg) => <h4 className="unselect" >{msg}</h4>
                    }
                </FormattedMessage>
            </div>
        </div >
    );
}

