import React from 'react';
import './footer.scss';

export default function FooterComponent(props: any) {
    return (
        <footer id={props.id} className="main-container-footer w-100 flex f-column a-center j-start">
            <div className="privacy-wrapper w-100 flex j-between mb-2 unselect">
                <a className="flex-center-all" target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/55621082">
                    <h6>Privacy Policy</h6>
                </a>
                <a className="flex-center-all" href="mailto:support@davideaccornero.com" title="support@davideaccornero.com" target="_blank" rel="noopener noreferrer">
                    <h6>Support</h6>
                </a>
                <a className="flex-center-all" href="https://davideaccornero.com/linktree" title="contacts" target="_blank" rel="noopener noreferrer">
                    <h6>Contacts</h6>
                </a>
                <a className="flex-center-all" target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/55621082/cookie-policy">
                    <h6>Cookie Policy</h6>
                </a>
            </div>
            <img src="assets/svg/logos/logo-cc-wh.svg" className="logo-footer pt-4 mb-4" alt="logo" />
            {/* <h2 className="mb-3" >DAVIDE ACCORNERO</h2> */}
            <h5>CO-FOUNDER ONEVISION</h5>
            <h4 className="mt-5 mb-5">CHECK ME ON:</h4>
            <div className="icons-container flex j-between a-end unselect mb-2">
                <a href="https://www.instagram.com/davide.accornero/?hl=it" target="_blank" rel="noopener noreferrer">
                    <img src="assets/svg/icons/instagram.svg" className="social-icon" alt="logo" />
                </a>
                <a href="https://www.tiktok.com/@davideaccornero97?" target="_blank" rel="noopener noreferrer">
                    <img src="assets/svg/icons/tiktok.svg" className="social-icon" alt="logo" />
                </a>
                <a href="https://it.linkedin.com/in/davide-accornero-210816188" target="_blank" rel="noopener noreferrer">
                    <img src="assets/svg/icons/linkedin.svg" className="social-icon" alt="logo" />
                </a>
                <a href="https://it-it.facebook.com/davide.accornero.9" target="_blank" rel="noopener noreferrer">
                    <img src="assets/svg/icons/facebook.svg" className="social-icon" alt="logo" />
                </a>
            </div>
            {/* <br />
            <br />
            <br />
            <br /> */}
        </footer>
    )
}


// {/* <h6 className="mb-4 unselect email" >Last Release:  10-06-2020 00:45</h6> */ }
