import React from 'react';
import './styles/claim.scss'
import { FormattedMessage } from 'react-intl';


export default function ClaimComponent(props: any) {
    // const logoPath = "assets/svg/logos/logo-cc-bk.svg"
    return (
        <div id={props.id} className="claim-container w-100 flex f-row a-center bg-notbk">
            <div className="left-claim w-45 h-100 flex j-center a-end p-3" >
                <img src="assets/svg/logos/icon-cc-wh.svg" className="logo" alt="logo" />
            </div>

            <div className="right-claim h-100 w-55 flex a-center j-end f-column pr-5 pl-5" >
                <h1><b><FormattedMessage id="claim.tutto-sta-per-cambiare" /></b></h1>
                <div className="quote mb-3 w-100"><FormattedMessage id="claim.citazione" /></div>
                <div className="author">- Charles Darwin -</div>
            </div>
        </div>
    );
}