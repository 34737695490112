import React from 'react';
import './guide.scss';
import { guideTiles, GuideTileI } from './guide-utils';
import { GuideTile } from './guide-tile/guide-tile-component';
import YellowButton from '../utils/button/button';
import { Carousel } from 'primereact/carousel';
import { FormattedMessage } from 'react-intl';
import firebase from 'firebase';


export default function GuideComponent(props: any) {
    const tiles: GuideTileI[] = guideTiles;
    const responsiveOptions = [
        {
            breakpoint: '992px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '760px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    return (
        <div className="guide-container w-100 flex f-column a-center pt-3">
           <FormattedMessage id="guide.sei-nel-posto-giusto" >
                {(msg: string) => (
                    // <div dangerouslySetInnerHTML={{ __html: msg }}></div>
                    <h1 className="mt-5" dangerouslySetInnerHTML={{ __html: msg }} ></h1>
                )}
            </FormattedMessage>
            <div id={props.id} className="w-100 desktop py-5 flex f-wrap f-row" >

                {
                    tiles.map((tile, index) => {
                        return (
                            <GuideTile key={"guide-tile-" + index} icon={tile.icon} description={tile.description} />
                        );
                    })
                }
                <div className="w-100 flex-center-all mt-4" >
                    <YellowButton onClick={() => { goToContacts() }} class="mt-4 pt-4" style={{ width: '60%' }} />
                </div>
            </div>
            <div className="mobile-visible w-100">
                <Carousel
                    value={tiles}
                    itemTemplate={tileTemplate}
                    responsiveOptions={responsiveOptions}
                />
                <div className="w-100 flex-center-all mt-4" >
                    <YellowButton onClick={() => { goToContacts() }} class="mt-4 pt-4" style={{ width: '60%' }} />
                </div>
            </div>
        </div>
    );
}

function tileTemplate(tile: GuideTileI) {
    return (

        <GuideTile key={"guide-tile-" + tile.icon} icon={tile.icon} description={tile.description} />
    );
}


function goToForm() {
    firebase.analytics().logEvent('contattami');
    document.location.hash = '';
    document.location.hash = '#form';
}

function goToContacts() {
    window.location.href = './linktree';
}