import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.scss';
import StartComponent from './components/start/start-component';
import FormComponent from './components/form/form-component';
import ReferenceComponent from './components/reference/reference-component';
import ClaimComponent from './components/claim/claim-component';
import AboutComponent from './components/about/about-component';
import FooterComponent from './components/footer/footer-component';
import ImagesComponent from './components/images/images-component';
import GuideComponent from './components/guide/guide-component';
import { IntlProvider } from "react-intl";
import it_translations from "./translations/it.json";
import en_translations from "./translations/en.json";
import { ThankYouPageComponent } from './thank-you-page/thank-you-page-component';
import { Redirect } from 'react-router';
import WitnessesComponent from './components/witnesses/witnesses-component';
import SwitchLanguage from './utils/switch-language';
import { LinkTreeComponent } from './linktree-page/linktree-page-component';

const translations: any = {
  'it': it_translations,
  'en': en_translations
};

export class App extends React.Component<any, any> {
  lang: string = 'it';
  constructor(props: any) {
    super(props);
    this.state = {
      lang: 'it'
    }
  }
  render() {

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {/* MAIN PAGE */}
            <Route exact path="/">
              <React.Fragment>
                <IntlProvider locale={this.state.lang} messages={translations[this.state.lang]}>
                  <SwitchLanguage onClick={x => {
                    this.setState((state: any) => {
                      if (state.lang === 'it') {
                        return { lang: 'en' }
                      } else {
                        return { lang: 'it' }
                      }
                    })
                  }} lang={this.state.lang} />
                  <StartComponent id="start" ></StartComponent>
                  <ClaimComponent id="claim"></ClaimComponent>
                  <AboutComponent id="about"></AboutComponent>
                  <GuideComponent id="guide" ></GuideComponent>
                  <ImagesComponent id="images"></ImagesComponent>
                  <WitnessesComponent></WitnessesComponent>
                  <ReferenceComponent id="reference"></ReferenceComponent>
                  {/* <PreFormComponent id="preform"></PreFormComponent> */}
                  <FormComponent id="form"></FormComponent>
                  <FooterComponent id="footer"></FooterComponent>
                </IntlProvider>
              </React.Fragment>
            </Route>
            {/* THANK YOU PAGE */}
            <Route path="/thank-you">
              <ThankYouPageComponent />
            </Route>
            {/* THANK YOU PAGE */}
            <Route path="/linktree">
              <LinkTreeComponent />
            </Route>
            {/* DEFAULT FALLBACK */}
            <Route path="/" >
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }

  // SITO PUBBLICO TEMPORANEO
  // <div className="App">
  //   <header className="App-header">
  //     <h1>Davide <b>Accornero</b></h1>
  //   </header>
  // </div>
}

export default App;
