import React from 'react';
import './image.scss';
import { FormattedMessage } from 'react-intl';

export interface ImageProps {
    title: string;
    description?: string;
    imageUrl: string;
    style?: React.CSSProperties;
    descriptionStyle?: React.CSSProperties;
    frozen?: boolean;
    hasLogo?: boolean;
}

export default class ImageComponent extends React.Component<ImageProps, { active: boolean }>  {
    style = {
        backgroundImage: "url(" + this.props.imageUrl + ")"
    }
    constructor(props: ImageProps) {
        super(props)
        this.state = {
            active: true
        }
        this.onclick = this.onclick.bind(this)
    }
    onclick() {
        console.log("imageclick")
        // && !this.state.active
        if (!this.props.frozen) {
            // this.setState((state) => {
            //     return { active: !state.active/* !state.active */ }
            // })
        }
    }
    render() {
        return (
            <div className="main-container-image w-100 main-container-image-active" onClick={this.onclick}>
                <div className="image-box w-100 flex f-column j-center a-center h-100 .o-hidden" style={{ ...this.style, ...this.props.style }} >

                    <FormattedMessage id={this.props.title} >
                        {(msg: string) => (
                            // <div dangerouslySetInnerHTML={{ __html: msg }}></div>
                            // <h3 className="guide-tile-description" dangerouslySetInnerHTML={{ __html: msg }}></h3>
                            <h1 className="image-title unselect" dangerouslySetInnerHTML={{ __html: msg }}></h1>
                        )}
                    </FormattedMessage>

                    {
                        this.props.description
                        &&
                        <FormattedMessage id={this.props.description} >
                            {(msg: string) => (
                                // <div dangerouslySetInnerHTML={{ __html: msg }}></div>
                                // <h3 className="guide-tile-description" dangerouslySetInnerHTML={{ __html: msg }}></h3>
                                // <h1 className={this.state.active ? "image-title image-title-active unselect" : "image-title image-title-inactive unselect"} dangerouslySetInnerHTML={{ __html: msg }}></h1>
                                <p className="image-description w-100 unselect"
                                    style={this.props.descriptionStyle || {}} dangerouslySetInnerHTML={{ __html: msg }}></p>
                            )}
                        </FormattedMessage>
                    }

                </div>
                <div className={this.props.hasLogo ? 'image-logo w-100 flex j-center' : 'd-none'}>DAVIDE ACCORNERO</div>
            </div >

        )
    }
}