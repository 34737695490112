import React from 'react';
import './witnesses-tile.scss';
import { WitnessesTileI } from '../witnesses-utils';
import { FormattedMessage } from 'react-intl';


export function WitnessesTile(props: WitnessesTileI) {
    return (
        <div className="main-tile flex-center-all w-100">
            <div className="witnesses-tile-container w-100 flex-center-all f-column bg-not-dark" >
                <img src={props.image} alt={props.title.toLowerCase()} className="witnesses-tile-image mb-4 unselect" />
                <h2 className="witnesses-tile-title mb-3 unselect" dangerouslySetInnerHTML={{ __html: props.title }} ></h2>
                <FormattedMessage id={props.description}>
                    {(msg: string) => (
                        <p className="witnesses-tile-description mb-5 unselect" dangerouslySetInnerHTML={{ __html: '&#34;' + msg + '&#34;' }}></p>
                    )}
                </FormattedMessage>
                <div className="social-icon-wrapper w-50 flex j-between unselect pb-3" >
                    <a href={props.instagram} target="_blank" rel="noopener noreferrer">
                        <img src="assets/svg/icons/instagram.svg" className="social-icon" alt="logo" />
                    </a>
                    <a href={props.tiktok} target="_blank" rel="noopener noreferrer">
                        <img src="assets/svg/icons/tiktok.svg" className="social-icon" alt="logo" />
                    </a>
                    <a href={props.linkedin} title={props.email} target="_blank" rel="noopener noreferrer">
                        <img src="assets/svg/icons/linkedin.svg" className="social-icon" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    );
}