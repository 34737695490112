import React from 'react';
import './start.scss';
import YellowButton from '../utils/button/button';
import MuteButton from '../utils/mute-button';
import firebase from '../../services/utils/firebase';

export default class StartComponent extends React.Component<any, any> {
    logoPath = "assets/svg/logo-claim-top-left-01.svg";
    signPath = "assets/images/utils/sign.png";

    videoPlayer: any;

    // componentDidMount() {
    //     this.videoPlayer = document.querySelector('#bk-v');
    //     this.videoPlayer.defaultPlaybackRate = 1.0;
    //     this.videoPlayer.playbackRate = 1.0;
    //     // console.log("this.videoplayer:", this.videoPlayer)
    //     setTimeout(() => {
    //         this.videoPlayer.muted = true;
    //         this.videoPlayer.play()
    //     }, 300);
    //     setTimeout(() => {
    //         this.videoPlayer.muted = true;
    //         this.videoPlayer.play()
    //     }, 61000);
    // }

    onClick = () => {
        document.location.hash = '';
        document.location.hash = '#about';
        firebase.analytics().logEvent('first-button', { timestamp: new Date().toISOString() });
    }


    render() {
        return (

            /*


                NON USARE LA CLASSE mt-4 SENZA ESSERE CONSAPEVOLE DEL CSS CUSTOM IN START.CSS PER QUESTO COMPONENTE

                VEDERE @MEDIA QUERY


            */

            <React.Fragment>
                <div id={this.props.id} className="main-container-start relative o-hidden" >
                    {/* Chrome 66 stops autoplay of all video that don't have the "muted" property. */}
                    {/* <video autoPlay={true} muted loop playsInline disablePictureInPicture id="bk-v">
                        <source
                            src="https://firebasestorage.googleapis.com/v0/b/davide-accornero.appspot.com/o/video%2Fmain-bg.mp4?alt=media&token=91dfcab8-8568-4108-8db2-faeb655ec975"
                            type="video/mp4" />
                    </video> */}
                    <MuteButton></MuteButton>
                    <div className="overlay flex-center-all w-100 h-100 relative">
                        <div className="left w-50 h-100 flex a-end j-center f-column logo" >
                            <div className="w-100 flex-center-all">
                            <img src={this.logoPath} className="start-logo" alt="logo" />
                            </div>
                            <div className="sign-wrapper w-50 flex f-column j-end">
                                <img src={this.signPath} className="sign" alt="logo" />
                            </div>
                        </div>
                        <div className="right w-50  f-column flex-center-all j-between py-5" >
                            <h1 className="pb-4 title" >DAVIDE ACCORNERO</h1>
                            <h2 className="pb-4 subtitles" >Personal <b>Growth.</b></h2>
                            <h2 className="pb-4 subtitles">Team <b>Leadership.</b></h2>
                            <h2 className="pb-4 mb-4 subtitles">Work <b>Revolution.</b></h2>
                            {/* <div className="button-container flex-center-all" > */}
                            <YellowButton  onClick={this.onClick} />
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}