import React from 'react';
import './thank-you.scss';

export class ThankYouPageComponent extends React.Component<any, { timerValue: number }>{

    interval;
    constructor(props: any) {
        super(props);
        this.state = {
            timerValue: 5
        }


         this.countdown = this.countdown.bind(this);
    }


     componentDidMount() {
         console.log("ciao")
         this.interval = setInterval(this.countdown, 1000);
     }

     countdown() {
         this.setState((state: any) => {
             if (state.timerValue >= 1) {
                 return { timerValue: state.timerValue - 1 }
             } else {
                 clearInterval(this.interval);
                 window.location.href = "https://www.instagram.com/davide.accornero/?hl=it";
                 return { timerValue: 0 }
             }
         })
     }

    render() {
        return (
            <div className="main-container-thankyou w-100 flex-center-all" >
                <div className="thankyou-container w-85 flex f-column j-between a-center">
                    <img src="assets/svg/logos/logo-cc-wh.svg" className="thankyou-logo" alt="thankyou" />
                    <h1>COMPLIMENTI PER AVER<br/> COMPILATO IL FORM!</h1>
                    <h2> Verrai Renderizzato In <br /> {this.state.timerValue} Secondi </h2>
                </div>
            </div>
        )
    }
}