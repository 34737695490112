import React from 'react';
import './images.scss';
import { Carousel } from 'primereact/carousel';
import ImageComponent, { ImageProps } from './image/image-component';
import { images, firstImage } from './images-utils';

export default class ImagesComponent extends React.Component<any, any> {

    firstImage = firstImage;
    images: ImageProps[] = images;
    responsiveOptions = [
        {
            breakpoint: '992px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    render() {
        return (
            <div id={this.props.id} >
                <div className="w-100 desktop">
                <ImageComponent key={'image-box'} title="images.conoscenze-troverai" imageUrl={this.firstImage.imageUrl} hasLogo={this.firstImage.hasLogo}
                    frozen={true}
                    style={this.firstImage.style} descriptionStyle={this.firstImage.descriptionStyle}></ImageComponent>
                {
                    this.images.map((x, i) => {
                        return <ImageComponent key={'image-box-' + i} title={x.title} description={x.description} imageUrl={x.imageUrl} style={x.style} descriptionStyle={x.descriptionStyle} hasLogo={x.hasLogo}></ImageComponent>
                    })
                }
                </div>
                <div className="mobile-visible w-100">
                    <Carousel
                        value={this.images}
                        itemTemplate={tileTemplate}
                        numVisible={1}
                        responsiveOptions={this.responsiveOptions}
                    />
                </div>
            </div>
        );
    }
}


function tileTemplate(image: ImageProps) {
    return (
        <ImageComponent key={'image-box'} title={image.title} description={image.description} imageUrl={image.imageUrl} hasLogo={false}
        frozen={true}
        style={image.style} descriptionStyle={image.descriptionStyle}></ImageComponent>
        );
}