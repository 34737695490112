import firebase from './utils/firebase';

export interface FormDataI {
    fullName: string;
    phone: number;
    email: string;

}

export async function formSubmit(data: FormDataI) {
    const db = firebase.firestore();
    var equalsEmailLength = await (await db.collection("messages").where('to', '==', data.email).get()).docs.length;
    if (equalsEmailLength > 0) {
        return undefined;
    }
    console.log("Email present: ", equalsEmailLength);
    const docName = '--> ' + new Date().toISOString() + ' <--';
    await db.collection("messages").doc(docName).set({
        to: [data.email, "info@davideaccornero.com"],
        message: {
            subject: 'Grazie ' + data.fullName.split(' ')[0].toUpperCase() + ' per aver compilato il form!',
            // text: 'Grazie, ' + data.fullName.split(' ')[0] + ', per avermi contattato! Ti risponderò entro due giorni lavorativi!',
            html: '<div style="color: #444444" >' +
                '<p style="color: #444444; font-size: 1.2rem;text-align: center">Ti informiamo che il form è stato <b>ricevuto correttamente.</b></p><br><br>' +
                '<p>I tuoi dati:</p><br>- ' + data.fullName + '<br>- ' + data.phone + '<br>- ' + data.email +
                '<br><br><p style="color: #444444; font-size: 1.1rem;text-align: center">Verrai contattato personalmente da me o dai miei collaboratori entro i prossimi giorni.<br><br>' +
                'Grazie e buona giornata,<br><br></p>' +
                '<h1 style="text-align: center"><b>Davide Accornero.</b></h1><br><br><br>' +
                '<p style="color: #bcbcbc;text-align: center" >La presente e-mail è stata generata automaticamente; si chiede cortesemente, pertanto, di non rispondere al messaggio.</p></div>'
        }
    })
    return db.collection("messages").doc(docName);
}