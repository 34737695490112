import React from 'react';
import './linktree.scss';

export class LinkTreeComponent extends React.Component<any, any> {
    render() {
        return (
            <div className="main-container-linktree w-100 flex-center-all" >
                <div className="linktree-container w-80 flex-center-all f-column j-between px-5 pt-4 pb-3">
                    <img src="assets/svg/logos/icon-cc-wh.svg" className="linktree-logo" alt="linktree" />
                    <button className="linktree-button flex a-center j-between w-30">
                        <a className="w-100 flex-center-all" href="https://davideaccornero.com" target="_blank" rel="noopener noreferrer">
                            <img src="assets/svg/icons/website.svg" className="linktree-icon" alt="logo" />
                            <p className="username w-85">Website Homepage</p>
                        </a>
                    </button>
                    <button className="linktree-button flex a-center j-between w-30">
                        <a className="w-100 flex-center-all" href="https://www.instagram.com/davide.accornero/?hl=it" target="_blank" rel="noopener noreferrer">
                            <img src="assets/svg/icons/instagram.svg" className="linktree-icon" alt="logo" />
                            <p className="username w-85">@davide.accornero</p>
                        </a>
                    </button>
                    <button className="linktree-button flex a-center j-between w-30">
                        <a className="w-100 flex-center-all" href="https://www.tiktok.com/@davideaccornero97?" target="_blank" rel="noopener noreferrer">
                            <img src="assets/svg/icons/tiktok.svg" className="linktree-icon" alt="logo" />
                            <p className="username w-85">@davide.accornero</p>
                        </a>
                    </button>
                    <button className="linktree-button flex a-center j-between w-30">
                        <a className="w-100 flex-center-all w-30" href="https://it.linkedin.com/in/davide-accornero-210816188" target="_blank" rel="noopener noreferrer">
                            <img src="assets/svg/icons/linkedin.svg" className="linktree-icon" alt="logo" />
                            <p className="username w-85">Davide Accornero</p>
                        </a>
                    </button>
                    <button className="linktree-button flex a-center j-between w-30">
                        <a className="w-100 flex-center-all" href="https://it-it.facebook.com/davide.accornero.9" target="_blank" rel="noopener noreferrer">
                            <img src="assets/svg/icons/facebook.svg" className="linktree-icon" alt="logo" />
                            <p className="username w-85">Davide Accornero</p>
                        </a>
                    </button>
                </div>
            </div>
        )
    }
}