import { ImageProps } from "./image/image-component";


export const firstImage: ImageProps = {
    title: "images.conoscenze-troverai",
    description: 'images.conoscenze-trovaerai',
    imageUrl: 'assets/images/letsgo-overlay.jpg',
    // descriptionStyle: { display: 'none'},
    frozen: true,
    hasLogo: true,
}

export const images: ImageProps[] = [
    {
        title: "images.conoscenze-title",
        description: "images.conoscenze-desc",
        imageUrl: 'assets/images/knowledge-overlay.jpg',
        hasLogo: true,
    },
    {
        title: "images.persone-title",
        description: "images.persone-desc",
        imageUrl: 'assets/images/people-overlay.jpg',
        hasLogo: true,
    },
    {
        title: "images.prospettive-title",
        description: "images.prospettive-desc",
        imageUrl: 'assets/images/perspectives-overlay.jpg',
        hasLogo: true,
    },
    {
        title: "images.risultati-title",
        description: "images.risultati-desc",
        imageUrl: 'assets/images/results-overlay.jpg',
        style: { backgroundPosition: 'bottom' },
        hasLogo: false,
    },
]