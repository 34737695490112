import React from 'react';
import './about.scss';
import { FormattedMessage } from 'react-intl';

export default class AboutComponent extends React.Component<any, any> {

    firstClick: boolean = true;

    constructor(props: any) {
        super(props);
        this.state = {
            viewDetails: false,
        }
    }

    render() {
        return (
            <React.Fragment>

                <div id={this.props.id + '_1'} className="main-container-about w-100 flex f-row a-center" >
                    <div className="left-about flex-center-all w-50 flex-center-all f-column" >
                        <h1 className="unselect w-100" ><FormattedMessage id="about.t1" /></h1>
                        <div className="about-start mb-4"><FormattedMessage id="about.a1">
                            {(msg: string) => (
                                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
                            )}</FormattedMessage>

                        </div>
                    </div>
                    <div className="right-about flex-center-all w-50" >
                        <img src="assets/images/about-bg.jpg" className="about-image" alt="about" />
                    </div>
                </div>
                <div id={this.props.id + '_2'} className="main-container-about w-100 flex f-row a-center reverse" >
                    <div className="left-about flex-center-all w-50 flex-center-all f-column" >
                        <h2 className="unselect w-100" ><FormattedMessage id="about.t2" /></h2>
                        <div className="about-start mb-4"><FormattedMessage id="about.a2">
                            {(msg: string) => (
                                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
                            )}</FormattedMessage>

                        </div>
                    </div>
                    <div className="right-about flex-center-all w-50" >
                        <img src="assets/images/about-dubai.jpg" className="about-image-reverse" alt="about" />
                    </div>
                </div>
                <div id={this.props.id + '_3'} className="main-container-about w-100 flex f-row a-center" >
                    <div className="left-about flex-center-all w-50 flex-center-all f-column" >
                        <h2 className="unselect w-100" ><FormattedMessage id="about.t3" /></h2>
                        <div className="about-start mb-4"><FormattedMessage id="about.a3">
                            {(msg: string) => (
                                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
                            )}</FormattedMessage>

                        </div>
                    </div>
                    <div className="right-about flex-center-all w-50" >
                        <img src="assets/images/about-team.jpg" className="about-image" alt="about" />
                    </div>
                </div>
                <div id={this.props.id + '_4'} className="main-container-about w-100 flex f-row a-center reverse" >
                    <div className="left-about flex-center-all w-50 flex-center-all f-column" >
                        <h2 className="unselect w-100" ><FormattedMessage id="about.t4" /></h2>
                        <div className="about-start mb-4"><FormattedMessage id="about.a4">
                            {(msg: string) => (
                                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
                            )}</FormattedMessage>

                        </div>
                    </div>
                    <div className="right-about flex-center-all w-50" >
                        <img src="assets/images/about-onevision.jpg" className="about-image-reverse" alt="about" />
                    </div>
                </div>
                <div id={this.props.id + '_5'} className="main-container-about w-100 flex f-row a-center" >
                    <div className="left-about flex-center-all w-50 flex-center-all f-column" >
                        <h2 className="unselect w-100" ><FormattedMessage id="about.t5" /></h2>
                        <div className="about-start mb-4"><FormattedMessage id="about.a5">
                            {(msg: string) => (
                                <p dangerouslySetInnerHTML={{ __html: msg }}></p>
                            )}</FormattedMessage>

                        </div>
                    </div>
                    <div className="right-about flex-center-all w-50" >
                        <img src="assets/images/about-final.jpg" className="about-image" alt="about" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}