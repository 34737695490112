import React from 'react';




export default function MuteButton(props: any) {
    return (
        <div></div>
        );

}