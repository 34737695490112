import React from 'react';
import './witnesses.scss';
import { witnessesTiles, WitnessesTileI } from './witnesses-utils';
import { Carousel } from 'primereact/carousel';
import { WitnessesTile } from './witnesses-tile/witnesses-tile-component';
import { FormattedMessage } from 'react-intl';


export default class WitnessesComponent extends React.Component<any, any>  {
    tiles: WitnessesTileI[] = witnessesTiles;
    responsiveOptions = [
        {
            breakpoint: '992px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    render() {
        return (
            <div>
                <div id={this.props.id} className="main-container-witnesses w-100 flex f-wrap p2" >

                    {/* {
                        this.tiles.map((tile, index) => {
                            return (
                                <WitnessesTile key={"guide-tile-" + index} icon={tile.icon} title={tile.title} description={tile.description} />
                            );
                        })
                    }
                    <div className="w-100 flex-center-all mt-4" >
                        <YellowButton onClick={() => { }} class="mt-4 pt-4" style={{ width: '60%' }} />
                    </div>
                </div> */}
                    {/* <div className="mobile-visible"> */}
                    {/* <FormattedMessage id="witnesses.header">
                    {(msg: string) => (
                        <h1 className="witnesses-header" dangerouslySetInnerHTML={{ __html: msg }}></h1>
                    )}
                </FormattedMessage> */}
                    <Carousel
                        value={this.tiles}
                        itemTemplate={tileTemplate}
                        numVisible={1}
                        responsiveOptions={this.responsiveOptions}
                    />
                    {/* <div className="w-100 flex-center-all mt-4" >
                        <YellowButton onClick={() => { }} class="mt-4 pt-4" style={{ width: '60%' }} />
                    </div> */}
                </div>
            </div>
        )
    }
}


function tileTemplate(tile: WitnessesTileI) {
    return (
        <WitnessesTile key={"witness-tile-" + tile.title} description={tile.description} image={tile.image} title={tile.title}
        email={tile.email} instagram={tile.instagram} tiktok={tile.tiktok} linkedin={tile.linkedin}></WitnessesTile>
    );
}