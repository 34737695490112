
export interface WitnessesTileI {
    image: string;
    title: string;
    description: string;
    email?: string;
    instagram?: string;
    tiktok?: string;
    linkedin?: string;
}

export const witnessesTiles: WitnessesTileI[] = [

    {
        image: 'assets/images/witnesses/elena-cambareri.png',
        title: 'ELENA CAMBARERI',
        description: 'witnesses.ec',
        email: "elena_1997@hotmail.it",
        tiktok: "https://vm.tiktok.com/ZM86oUD3N/",
        instagram: "https://www.instagram.com/elena_cambareri/",
        linkedin: "https://it.linkedin.com/in/elena-cambareri-66a048192?trk=people-guest_people_search-card"
    },
    {
        image: 'assets/images/witnesses/michael-manueddu.png',
        title: 'MICHAEL MANUEDDU',
        description: 'witnesses.mm',
        email: "michaelmanueddu@gmail.com",
        instagram: "https://www.instagram.com/michaelmanueddu/",
        linkedin: "https://it.linkedin.com/in/michael-manueddu/it-it"
    },
    {
        image: 'assets/images/witnesses/marco-boi.png',
        title: 'MARCO BOI',
        description: 'witnesses.mbo',
        email: "marcoboiwork@gmail.com",
        instagram: "https://www.instagram.com/marcoboi_/",
        tiktok: "https://vm.tiktok.com/ZM86oPJLb/",
        linkedin: "https://it.linkedin.com/in/marco-boi-7001a6196?trk=people-guest_people_search-card"
    },
    {
        image: 'assets/images/witnesses/matteo-nussan.png',
        title: 'MATTEO NOUSSAN',
        description: 'witnesses.mn',
        email: "matteo.noussan@yahoo.it",
        instagram: "https://www.instagram.com/matteonoussan/",
        linkedin: "https://it.linkedin.com/in/matteo-noussan-441892116?challengeId=AQFpAXAUvI00_gAAAXK5k_hfkbzeC2YKrmfAhohsliAjFQCcLWKi-wNMVZ1vh06pgiUuPHKd65J-ox8WvaLBg8zV_3GcznA2hg&submissionId=09615503-32d0-1816-c806-9fc851f5e333"
    },
    {
        image: 'assets/images/witnesses/agostino-pisu.png',
        title: 'AGOSTINO PISU',
        description: 'witnesses.ap',
        instagram: "https://www.instagram.com/stinop/",
        tiktok: "https://vm.tiktok.com/ZM86oPJLb/"
    },
    {
        image: 'assets/images/witnesses/cristian-boi.png',
        title: 'CRISTIAN BOI',
        description: 'witnesses.cb',
        instagram: "https://www.instagram.com/cristianboi/"
    },
    {
        image: 'assets/images/witnesses/michel-belfrond.png',
        title: 'MICHEL BELFROND' ,
        description: 'witnesses.mchb',
        instagram: "https://www.instagram.com/michelbelfrond/"
    },
];
