import React from 'react';
import './reference.scss';
import { FormattedMessage } from 'react-intl';

export default function ReferenceComponent(props: any) {
    return (
        <div id={props.id} className="main-container-reference w-100 flex-center-all f-column">
            <img src="assets/images/reference.png" className="reference-image mb-2" alt="Alex L. Herciu" />
            <p className="mt-4 paragrafo unselect" >
                <FormattedMessage id="reference.p0" />
                <br />
                <FormattedMessage id="reference.p1" />
                <br />
                <FormattedMessage id="reference.p2" />
                <br />
                <FormattedMessage id="reference.p3" />
                <br />
                <FormattedMessage id="reference.p4" />
            </p>
            <h3 className="special-testimonial mt-3 mb-4 unselect"  >SPECIAL TESTIMONIAL: ALEX L. HERCIU</h3>
            <hr className="mt-4 mb-3" style={{ width: '15%', borderColor: '#7f7f7f' }} />
            <h6 className="author mt-4 unselect"  ><FormattedMessage id="reference.h6" /></h6>
        </div>
    );
}