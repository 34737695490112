import React from 'react';
import './form.scss'
import { FormDataI, formSubmit } from '../../services/form-service';
import firebase from '../../services/utils/firebase';
import { Growl } from 'primereact/growl';

import { InputText } from 'primereact/inputtext';

export interface FormState {
    fullName: string;
    cellulare: string;
    email: string;
    canSubmit: boolean;
    [key: string]: any;
}

export default class FormComponent extends React.Component<any, FormState> {

    growl: any;

    submitComplete: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            fullName: "",
            cellulare: "",
            email: "",
            canSubmit: false
        }
    }


    onFullNameChange = (e: any) => {
        this.setState(() => {
            const obj = { fullName: e }
            return obj;
        }, () => {
            this.validator();
        }
        );
    }

    onPhoneChange = (e: any) => {
        this.setState(() => {
            const obj = { cellulare: e }
            return obj;
        }, () => {
            this.validator();
        }
        );
    }

    onEmailChange = (e: any) => {
        this.setState(() => {
            const obj = { email: e }
            return obj;
        }, () => {
            this.validator();

        }
        );
    }


    validator = () => {
        if (this.isValidValue(this.state.fullName) && this.isValidValue(this.state.cellulare) && this.isValidValue(this.state.email) && this.isValidEmail(this.state.email)) {
            this.setState({ canSubmit: true });
        } else {
            this.setState({ canSubmit: false });
        }
    }

    isValidValue = (x: any) => {
        return x !== "" && x !== null && x !== undefined;
    }

    isValidEmail = (x: string) => {
        return x.includes("@")
            && x.includes(".")
            && !x.endsWith("@")
            && !x.endsWith(".")
            && !x.startsWith(".")
            && !x.startsWith("@")
            && x.length > 7;
    }


    onSubmit = async () => {
        const data: FormDataI = {
            fullName: this.state.fullName,
            phone: parseInt(this.state.cellulare),
            email: this.state.email
        };
        if (this.state.canSubmit && !this.submitComplete) {
            const res = await formSubmit(data);
            if (res === undefined) {
                firebase.analytics().logEvent('form-email-gia-registrata');
                this.growl.show({ severity: 'warn', summary: 'Email già registrata', detail: 'Questa mail è già stata registrata, riceverai risposta a breve!' });
            } else {
                await res.get().then((x) => {
                    const data = x.data();
                    console.log("DocRef:", data);
                    firebase.analytics().logEvent('form-completato');
                    this.growl.show({ severity: 'success', summary: 'Candidatura inviata', detail: 'Riceverai una mail di conferma' });
                    this.setState(() => {
                        this.submitComplete = true;

                        return {
                            email: "",
                            fullName: "",
                            cellulare: "",
                            canSubmit: false
                        }
                    }, () => {
                        setTimeout(() => {
                            console.log("Navigating to thank you page.");
                            window.location.href = './thank-you';
                        }, 800);
                    })
                }).catch((err) => {
                    console.error("catch", err);
                    firebase.analytics().logEvent('form-error');
                    this.growl.show({ severity: 'error', summary: 'Errore imprevisto', detail: 'Prova a ricaricare la pagina e riprova.' });
                });
            }

        } else {
            firebase.analytics().logEvent('tentato-form-invalido', data);
            this.growl.show({ severity: 'warn', summary: 'Form incompleto', detail: 'I campi del form non sono validi' });
        }

    }


    render() {
        return (
            <React.Fragment>
                <Growl ref={(el) => this.growl = el} />
                <form id={this.props.id} >
                    <div className={"main-container-form w-100 flex-center-all" + (this.submitComplete ? ' submit-complete' : '')} >
                        <div className="form-container w-40 h-100 flex-center-all f-column j-between" >
                            <h3 className="unselect" >CHE ASPETTI? <br/> CANDIDATI <b>QUI</b></h3>
                            {/* NOME E COGNOME */}
                            <span style={{ width: '54%' }} className="mt-3 p-float-label">
                                <InputText style={{ width: "100%" }} className="p-3" id="fullName" name="fullName" value={this.state.fullName} onChange={(e: any) => this.onFullNameChange(e.target.value)} />
                                <label htmlFor="fullName" className={this.state.canSubmit || this.isValidValue(this.state.fullName) ? "label-up" : ""}>Nome e Cognome</label>
                            </span>
                            {/* NUMERO DI TELEFONO */}
                            <span style={{ width: '54%' }} className="mt-3 p-float-label">
                                <InputText style={{ width: "100%" }} keyfilter="num" className="p-3" name="phone" id="phone" value={this.state.cellulare} onChange={(e: any) => this.onPhoneChange(e.target.value)} />
                                <label htmlFor="phone" className={(this.state.canSubmit || this.isValidValue(this.state.cellulare)) ? "label-up" : ""}>Numero di telefono</label>
                            </span>
                            {/* EMAIL */}
                            <span style={{ width: '54%' }} className="mt-3 p-float-label">
                                <InputText style={{ width: "100%" }} keyfilter="email" className="p-3" id="email" name="email" value={this.state.email} onChange={(e: any) => { this.onEmailChange(e.target.value) }} />
                                <label htmlFor="email" className={this.state.canSubmit || this.isValidValue(this.state.email) ? "label-up" : ""} >Email</label>
                            </span>
                            <div id="submit" onClick={(e: any) => this.onSubmit()} className={"submit-contianer w-45 flex-center-all mt-4 pr-3 pl-3 pt-2 pb-2 unselect" + (this.state.canSubmit ? "" : " disabled")} >
                                <p className="unselect" >INVIA LA CANDIDATURA</p>
                            </div>

                        </div>
                    </div >
                </form>
            </React.Fragment>
        )
    }
}