import React from 'react';
import './guide-tile.scss';
import { GuideTileI } from '../guide-utils';
import { FormattedMessage } from 'react-intl';


export function GuideTile(props: GuideTileI) {
    return (
        <div className="mb-4 mt-5 guide-tile-container" >
            <img src={"assets/svg/icons/" + props.icon} alt="tile" className="guide-tile-icon" />
            <FormattedMessage id={props.description} >
                {(msg: string) => (
                    // <div dangerouslySetInnerHTML={{ __html: msg }}></div>
                    <h4 className="guide-tile-description mt-4" dangerouslySetInnerHTML={{ __html: msg }}></h4>
                )}
            </FormattedMessage>
        </div>
    );
}
