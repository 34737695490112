import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';



const config = {
    apiKey: "AIzaSyB3YGkr7XahAFJFo3dcKZvkBuS1hnAyohY",
    authDomain: "davide-accornero.firebaseapp.com",
    databaseURL: "https://davide-accornero.firebaseio.com",
    projectId: "davide-accornero",
    storageBucket: "davide-accornero.appspot.com",
    messagingSenderId: "242189318831",
    appId: "1:242189318831:web:f8ed27864179ed3e3c325a",
    measurementId: "G-GCQC099MHC"
};


firebase.initializeApp(config);
export const analytics = firebase.analytics();

export default firebase;