
export interface GuideTileI {
    icon: string;
    description: string;
}

export const guideTiles: GuideTileI[] = [
    {
        icon: '1.svg',
        description: 'guide.g1',
    },
    {
        icon: '2.svg',
        description: 'guide.g2',
    },
    {
        icon: '3.svg',
        description: 'guide.g3',
    },
    {
        icon: '4.svg',
        description: 'guide.g4',
    },
    {
        icon: '5.svg',
        description: 'guide.g5',
    },
    {
        icon: '6.svg',
        description: 'guide.g6'
    },
];
